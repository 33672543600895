import React from 'react'

import ImageBackground from 'components/blocks/ImageBackground'
import ErrorPageContent from 'components/blocks/ErrorPageContent'

const Http404: React.FC = (): React.ReactElement => (
  <ImageBackground>
    <ErrorPageContent code={404} title='Not Found' />
  </ImageBackground>
)

export default Http404
